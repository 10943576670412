import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
       <a href="https://twitter.com/AlaaMah76890214"><BsTwitter /></a>
      
    </div>
    <div>
      <a href = "https://www.facebook.com/raf.nadal.3">
      <FaFacebookF  /></a>
    </div>
    <div>
      <BsInstagram />
    </div>
  </div>
);

export default SocialMedia;